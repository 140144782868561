<template>
  <div class="pagination">
    <a
      @click="goPage(page - 1)"
      style="cursor: pointer"
      class="page-btn prev "
      v-bind:class="{ dis: isFirst }"
      ><span class="is-blind">이전페이지</span></a
    >
    <a
      @click="goPage(pageItem)"
      style="cursor: pointer"
      class="page-btn num "
      v-for="pageItem in pageArray"
      v-bind:key="pageItem"
      v-bind:class="{'is-active': page == pageItem}"
      ><span class="num-txt">{{ pageItem }}</span>
    </a>
    <a
      @click="goPage(page * 1 + 1)"
      style="cursor: pointer"
      class="page-btn next"
      v-bind:class="{ dis: isLast }"
      ><span class="is-blind">다음페이지</span></a
    >
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["page", "location","pageSize","pageParamName","pageSizeParamName" ,"extraParamValues"],
  data() {
    return {
      buttonStyle: {},
      isFirst: false,
      isLast: false,
      total: 0,
      totalPage: 0,
      pageArray: []
    };
  },
  beforeMount() {
    console.log("this.page", this.page);
    if (this.page == 1) {
      this.isFirst = true;
    }
  },
  mounted() {},
  methods: {
    goPage: function(page) {
      let paramName = "page";
      if (this.pageParamName != undefined && this.pageParamName != null ) {
        paramName = this.pageParamName;
      }
      var queryJson = "{ \"" + paramName + "\":" + page;
      let pageSizeName = "pageSize";
      if (this.pageSizeParamName != undefined && this.pageSizeParamName != null ) {
        pageSizeName = this.pageSizeParamName;
      }
      queryJson = queryJson + " ,\"" + pageSizeName + "\":" + this.pageSize ;

      if (this.extraParamValues != undefined && this.extraParamValues != null ) {
        queryJson = queryJson + "," + this.extraParamValues.replace(/'/gi,"\"") ;
      }
      queryJson = queryJson + " }";
      console.log(queryJson);
      const queryParam = JSON.parse(queryJson);
      if (page > 0 && page <= this.totalPage && page != this.page) {
        this.$router.push({ path: this.location, query: queryParam });
      }

    },
    setTotal: function(value) {
      this.total = value;

      this.setPagenation();
      if (this.page == this.totalPage) {
        this.isLast = true;
      }
      console.log("-------------------------------------------");
      console.log("this.total : " + this.total);
      console.log("this.isLast : " + this.isLast);
      console.log("-------------------------------------------");
    },
    setPagenation: function() {
      this.pageArray = [];
      this.totalPage = Math.ceil(this.total / this.pageSize);

      if (this.page <= 9) {
        if (this.totalPage > 9) {
          for (var i = 1; i <= 9; i++) {
            this.pageArray.push(i);
          }
        } else {
          for (var ii = 1; ii <= this.totalPage; ii++) {
            this.pageArray.push(ii);
          }
        }
      } else if (
        this.page > 5 &&
        this.totalPage > 9 &&
        this.totalPage - this.page > 9
      ) {
        for (var m = this.page - 4; m < this.page + 4; m++) {
          this.pageArray.push(m);
        }
      } else if (this.totalPage - this.page < 9) {
        for (var j = this.totalPage - 9; j <= this.totalPage; j++) {
          this.pageArray.push(j);
        }
      }
    }
  }
};
</script>

<style scoped></style>
